export default [
  {
    text: "Выбор номинации",
    link: { name: "AchievementYearGrandPrixNomination" },
    key: "nominations",
    disabled: false,
  },
  {
    text: "Данные",
    link: { name: "AchievementYearGrandPrixData" },
    key: "profile",
    disabled: false,
  },
  {
    text: "Успеваемость",
    link: { name: "AchievementYearGrandPrixPerformance" },
    key: "performance",
    disabled: false,
  },
  {
    text: "Портфолио",
    link: { name: "AchievementYearGrandPrixPortfolio" },
    key: "portfolio",
    disabled: false,
  },
];
